<template>
<div>
  <div v-if="showLoading" class="text-center" style="padding: 24px">
    <CSpinner/>
  </div>

  <div v-if="!showLoading">
    <CRow style="margin-bottom:20px">
      <CCol>
        <CButton
          color="success"
          variant="outline"
          square
          size="sm"
          @click="onClickNewCity()">
          Kota baru
        </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CDataTable
        :items="items"
        :fields="fields"
        :items-per-page="30"
        hover
        pagination>
        <template #show_edit="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="onClickEdit(item, index)">
              Edit
            </CButton>
          </td>
        </template>
        <template #show_delete="{item, index}">
          <td class="py-2">
            <CButton
              color="danger"
              variant="outline"
              square
              size="sm"
              @click="onClickDelete(item, index)">
              Delete
            </CButton>
          </td>
        </template>      
      </CDataTable>
    </CRow>
  </div>

  <CModal :show.sync="showDialog" :title="title" hide-footer>
    <div style="padding: 10px">
      <CInput
        label="Nama kota"
        v-model="record.city"
        horizontal
      />
    </div>

    <div slot="footer">
      <CButton @click="dialogClose()">Batal</CButton>
      <CButton @click="dialogSave()" color="success">Simpan</CButton>
    </div>
  </CModal>
</div>
</template>

<script>
  import api from "../../api/api"

  const fields = [
    { 
      key: 'city', 
      label: 'Nama kota', 
      _style: 'min-width:100px'
    },
    { 
      key: 'show_edit', 
      label: '', 
      _style: 'width:1%'
    },
    { 
      key: 'show_delete', 
      label: '', 
      _style: 'width:1%'
    }
  ]

  export default {
    data () {
      return {
        showLoading: false,
        items: [],
        fields,
        details: [],

        showDialog: false,
        title: '',
        isEdit: true,
        record: '',
        user: ''
      }
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else{
        this.user = JSON.parse(localStorage.getItem('user'))
        this.listCity()
      }
    },

    methods: {
      async listCity () {
        this.showLoading = true
        this.items = []
        let apiRes = await api.listCity()
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.items = apiRes.cities
        } 
        else {
          console.log("listCity ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      onClickEdit (item) {
        this.isEdit = true
        this.title = "Edit Kota"
        this.record = item
        this.showDialog = true
      },

      async onClickDelete (item) {
        this.record = item
        this.$confirm("Yakin untuk menghapus kota ini?").then(() => {
          this.executeDeleteCity()
        });
      },

      async executeDeleteCity(){
        this.showLoading = true
        let apiRes = await api.deleteCity(this.user.id, this.record.id)
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.$alert("Kota sudah dihapus!")
          this.listCity()
        } 
        else {
          if(apiRes.err==="USED")
            this.$alert("Tidak bisa menghapus karena kota ini sudah digunakan untuk jadwal Sion Raya")

          else{
            console.log("deleteCity ERR: " + apiRes.err)
            this.$router.replace('/login', () => {})
          }
        }      
      },

      dialogClose(){
        this.showDialog = false
      },

      async dialogSave(){
        if(this.record.city.trim()==='')
          this.$alert("Masukkan nama kota")
        else{
          this.showDialog = false

          if(this.isEdit){
            this.showLoading = true
            let apiRes = await api.updateCity(this.user.id, this.record.id, this.record.city)
            this.showLoading = false

            if (apiRes.result === 'OK') {
              this.listCity()
            } 
            else {
              console.log("updateCity ERR: " + apiRes.err)
              this.$router.replace('/login', () => {})
            }
          }
          else{
            this.showLoading = true
            let apiRes = await api.createCity(this.user.id, this.record.city)
            this.showLoading = false

            if (apiRes.result === 'OK') {
              this.listCity()
            } 
            else {
              console.log("createCity ERR: " + apiRes.err)
              this.$router.replace('/login', () => {})
            }
          }
        }
      },

      onClickNewCity(){
        this.isEdit = false
        this.title = "Buat Kota baru"
        this.record = { city:'' }
        this.showDialog = true
      },

    },

  }
</script>
